import React, { useState } from "react";
import { staticQuizContext as StaticQuizContext } from "./context";

export const nextQuestionNumberKey = "nextQuestionNumber";

const StaticQuizState = (props) => {
  const currentStepKey = "currentStep";
  const [currentStep, setCurrentStepState] = useState(
    Number(sessionStorage.getItem(currentStepKey)) || 1
  );
  const setCurrentStep = (step) => {
    setCurrentStepState(step);
    sessionStorage.setItem(currentStepKey, step);
  };

  const quizKeyKey = "quizKey";
  const [quizKey, setQuizKeyState] = useState(
    sessionStorage.getItem(quizKeyKey) || null
  );
  const setQuizKey = (key) => {
    setQuizKeyState(key);
    if (key) {
      sessionStorage.setItem(quizKeyKey, key);
    } else {
      sessionStorage.removeItem(quizKeyKey);
    }
  };

  const selectedCategoriesKey = "selectedCategories";
  const [selectedCategories, setSelectedCategoriesState] = useState(
    JSON.parse(sessionStorage.getItem(selectedCategoriesKey)) || []
  );
  const setSelectedCategories = (categories) => {
    setSelectedCategoriesState(categories);
    if (categories && Array.isArray(categories) && categories.length > 0) {
      sessionStorage.setItem(selectedCategoriesKey, JSON.stringify(categories));
    } else {
      sessionStorage.removeItem(selectedCategoriesKey);
    }
  };

  const quizDifficultyLevelKey = "quizDifficultyLevel";
  const [quizDifficultyLevel, setQuizDifficultyLevelState] = useState(
    sessionStorage.getItem(quizDifficultyLevelKey) || null
  );
  const setQuizDifficultyLevel = (level) => {
    setQuizDifficultyLevelState(level);
    if (level) {
      sessionStorage.setItem(quizDifficultyLevelKey, level);
    } else {
      sessionStorage.removeItem(quizDifficultyLevelKey);
    }
  };

  const staticQuizQuestionsKey = "staticQuizQuestions";
  const [staticQuizQuestions, setStaticQuizQuestionsState] = useState(
    JSON.parse(sessionStorage.getItem(staticQuizQuestionsKey)) || []
  );
  const setStaticQuizQuestions = (questions) => {
    setStaticQuizQuestionsState(questions);
    if (questions && Array.isArray(questions) && questions.length > 0) {
      sessionStorage.setItem(staticQuizQuestionsKey, JSON.stringify(questions));
    } else {
      sessionStorage.removeItem(staticQuizQuestionsKey);
    }
  };

  const selectedQuestionNoKey = "selectedQuestionNo";
  const [selectedQuestionNo, setSelectedQuestionNoState] = useState(() => {
    const nextQuestionNumber = Number(
      sessionStorage.getItem(nextQuestionNumberKey)
    );
    return (
      nextQuestionNumber ||
      Number(sessionStorage.getItem(nextQuestionNumberKey)) ||
      0
    );
  });
  const setSelectedQuestionNo = (questionNo) => {
    setSelectedQuestionNoState(questionNo);
    if (questionNo) {
      sessionStorage.setItem(selectedQuestionNoKey, questionNo);
    } else {
      sessionStorage.removeItem(selectedQuestionNoKey);
    }
  };

  const selectNextQuestion = () => {
    const nextQuestionNumber =
      Number(sessionStorage.getItem(nextQuestionNumberKey)) || undefined;
    if (nextQuestionNumber && staticQuizQuestions.length > nextQuestionNumber) {
      setSelectedQuestionNo(nextQuestionNumber);
    }
  };

  const isStaticQuizModeKey = "isStaticQuizMode";
  const [isStaticQuizMode, setIsStaticQuizModeState] = useState(
    sessionStorage.getItem(isStaticQuizModeKey) === "true" || false
  );
  const setIsStaticQuizMode = (isStaticQuizMode) => {
    setIsStaticQuizModeState(isStaticQuizMode);
    if (isStaticQuizMode) {
      sessionStorage.setItem(isStaticQuizModeKey, isStaticQuizMode);
    } else {
      sessionStorage.removeItem(isStaticQuizModeKey);
    }
  };
  const startStaticQuizMode = () => {
    setIsStaticQuizMode(true);
  };

  const [userRanking, setUserRanking] = useState(null);

  const noOfCorrectlyAnsweredQuestionsKey = "noOfCorrectlyAnsweredQuestions";
  const [
    noOfCorrectlyAnsweredQuestions,
    setNoOfCorrectlyAnsweredQuestionsState,
  ] = useState(
    Number(sessionStorage.getItem(noOfCorrectlyAnsweredQuestionsKey)) || 0
  );
  const setNoOfCorrectlyAnsweredQuestions = (
    noOfCorrectlyAnsweredQuestions
  ) => {
    setNoOfCorrectlyAnsweredQuestionsState(noOfCorrectlyAnsweredQuestions);
    if (
      noOfCorrectlyAnsweredQuestions !== undefined &&
      noOfCorrectlyAnsweredQuestions !== null
    ) {
      sessionStorage.setItem(
        noOfCorrectlyAnsweredQuestionsKey,
        noOfCorrectlyAnsweredQuestions
      );
    } else {
      sessionStorage.removeItem(noOfCorrectlyAnsweredQuestionsKey);
    }
  };

  const noOfInCorrectlyAnsweredQuestionsKey =
    "noOfInCorrectlyAnsweredQuestions";
  const [
    noOfInCorrectlyAnsweredQuestions,
    setNoOfInCorrectlyAnsweredQuestionsState,
  ] = useState(
    Number(sessionStorage.getItem(noOfInCorrectlyAnsweredQuestionsKey)) || 0
  );
  const setNoOfInCorrectlyAnsweredQuestions = (
    noOfInCorrectlyAnsweredQuestions
  ) => {
    setNoOfInCorrectlyAnsweredQuestionsState(noOfInCorrectlyAnsweredQuestions);
    sessionStorage.setItem(
      noOfInCorrectlyAnsweredQuestionsKey,
      noOfInCorrectlyAnsweredQuestions
    );
  };

  const resetStaticQuizState = () => {
    setIsStaticQuizMode(false);
    setCurrentStep(1);
    setQuizKey(null);
    sessionStorage.removeItem(nextQuestionNumberKey);
    setSelectedCategories([]);
    setQuizDifficultyLevel(null);
    setStaticQuizQuestions([]);
    setSelectedCategories([]);
    setSelectedQuestionNo(0);
    setUserRanking(null);
    setNoOfCorrectlyAnsweredQuestions(0);
    setNoOfInCorrectlyAnsweredQuestions(0);
  };

  return (
    <StaticQuizContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        quizKey,
        setQuizKey,
        selectedCategories,
        setSelectedCategories,
        quizDifficultyLevel,
        setQuizDifficultyLevel,
        resetStaticQuizState,
        isStaticQuizMode,
        startStaticQuizMode,
        staticQuizQuestions,
        setStaticQuizQuestions,
        selectedQuestionNo,
        selectNextQuestion,
        userRanking,
        setUserRanking,
        noOfCorrectlyAnsweredQuestions,
        setNoOfCorrectlyAnsweredQuestions,
        noOfInCorrectlyAnsweredQuestions,
        setNoOfInCorrectlyAnsweredQuestions,
      }}
    >
      {props.children}
    </StaticQuizContext.Provider>
  );
};

export default StaticQuizState;
