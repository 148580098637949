export const OTP_SIZE = +process.env.REACT_APP_OTP_SIZE;
export const OTP_RE_REQUEST_TIME_IN_SECONDS =
  +process.env.REACT_APP_OTP_RE_REQUEST_TIME_IN_SECONDS;
export const NO_OF_QUESTIONS_PER_STATIC_QUIZ =
  +process.env.REACT_APP_NO_OF_QUESTIONS_PER_STATIC_QUIZ;
export const STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS =
  +process.env.REACT_APP_STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS;
export const STATIC_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS =
  +process.env
    .REACT_APP_STATIC_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS;
export const STATIC_QUIZ_MINIMUM_PERCENTAGE_TO_PASS =
  +process.env.REACT_APP_STATIC_QUIZ_MINIMUM_PERCENTAGE_TO_PASS;
export const LIVE_QUIZ_TIME_PER_QUESTION_IN_SECONDS =
  +process.env.REACT_APP_LIVE_QUIZ_TIME_PER_QUESTION_IN_SECONDS;
export const LIVE_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS =
  +process.env
    .REACT_APP_LIVE_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS;
export const LIVE_QUIZ_MINIMUM_PERCENTAGE_TO_PASS =
  +process.env.REACT_APP_LIVE_QUIZ_MINIMUM_PERCENTAGE_TO_PASS;
export const BUFFER_TO_REFRESH_TOKEN_IN_SECONDS =
  +process.env.REACT_APP_BUFFER_TO_REFRESH_TOKEN_IN_SECONDS;
export const AUTO_LOGIN_TIME_BETWEEN_ATTEMPTS_IN_SECONDS =
  +process.env.REACT_APP_AUTO_LOGIN_TIME_BETWEEN_ATTEMPTS_IN_SECONDS;
export const AUTO_LOGIN_MAX_NO_OF_ATTEMPTS =
  +process.env.REACT_APP_AUTO_LOGIN_MAX_NO_OF_ATTEMPTS;
export const AUTO_SUBSCRIPTION_UNSUCCESSFUL_MESSAGE =
  process.env.REACT_APP_AUTO_SUBSCRIPTION_UNSUCCESSFUL_MESSAGE;
export const UNSUBSCRIBE_MESSAGE = process.env.REACT_APP_UNSUBSCRIBE_MESSAGE;
export const AGORA_RTM_APP_ID = process.env.REACT_APP_AGORA_RTM_APP_ID;
export const AGORA_RTM_CHANNEL_NAME =
  process.env.REACT_APP_AGORA_RTM_CHANNEL_NAME;
export const HEADER_ENRICHMENT_HEADER_KEY_FOR_MSISDN =
  process.env.REACT_APP_HEADER_ENRICHMENT_HEADER_KEY_FOR_MSISDN;
export const HEADER_ENRICHMENT_ENABLED =
  process.env.REACT_APP_HEADER_ENRICHMENT_ENABLED === "true";
export const MTA_AUTO_LOGIN_FEATURE_ENABLED =
  process.env.REACT_APP_MTA_AUTO_LOGIN_FEATURE_ENABLED === "true";
// STATIC QUIZ POP UP TEXT
export const STATIC_QUIZ_POP_UP_TITLE =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_TITLE;
export const STATIC_QUIZ_POP_UP_ITEM_1 =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_ITEM_1;
export const STATIC_QUIZ_POP_UP_ITEM_2 =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_ITEM_2;
export const STATIC_QUIZ_POP_UP_ITEM_3 =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_ITEM_3;
export const STATIC_QUIZ_POP_UP_ITEM_4 =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_ITEM_4;
export const STATIC_QUIZ_POP_UP_ITEM_5 =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_ITEM_5;
export const STATIC_QUIZ_POP_UP_ITEM_6 =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_ITEM_6;
export const STATIC_QUIZ_POP_UP_ITEM_7 =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_ITEM_7;

// STATIC QUIZ POP UP TEXT KEYS
export const STATIC_QUIZ_POP_UP_KEY_NO_OF_QUESTIONS =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_KEY_NO_OF_QUESTIONS;
export const STATIC_QUIZ_POP_UP_KEY_TIME_PER_QUESTION =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_KEY_TIME_PER_QUESTION;
export const STATIC_QUIZ_POP_UP_KEY_NO_OF_WINNERS =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_KEY_NO_OF_WINNERS;
export const STATIC_QUIZ_POP_UP_KEY_TIME_DURATION_OF_A_SEASON =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_KEY_TIME_DURATION_OF_A_SEASON;
export const STATIC_QUIZ_POP_UP_KEY_REWARD_AMOUNT =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_KEY_REWARD_AMOUNT;

// STATIC QUIZ POP UP DATA
export const STATIC_QUIZ_POP_UP_NO_OF_WINNERS =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_NO_OF_WINNERS;
export const STATIC_QUIZ_POP_UP_TIME_DURATION_OF_A_SEASON =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_TIME_DURATION_OF_A_SEASON;
export const STATIC_QUIZ_POP_UP_REWARD_AMOUNT =
  process.env.REACT_APP_STATIC_QUIZ_POP_UP_REWARD_AMOUNT;
